<template>
  <div class="container">
    <table-page dataName="marketer_configs"
                ref="table"
                :search="search"
                :params='{activity_id:$route.query.id}'
                @on-success="fetchSuccess"
                :request="fetchPacketEmployees">
      <template slot="button">
        <el-button type="primary"
                   v-permission="['ADMIN']"
                   class="mr-15"
                   @click="$refs.setEmployee.showModel()">添加员工</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   v-if="exportUrl"
                   @click="exportNetsData">导出网点数据</el-button>
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="downloadExecl">下载员工号码模板</el-button>
        <FileUpload action="/api/mall/admin/marketer/marketer/import"
                    :params="{activity_id:$route.query.id}"
                    class="mr-15"
                    @on-success="fileSuccess"
                    title="导入文件" />
        <FileExport :params="exportParams">导出员工</FileExport>

      </template>
      <el-table-column prop="name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="total_issue"
                       show-overflow-tooltip
                       label="已派发人数">
      </el-table-column>
      <el-table-column prop="money"
                       show-overflow-tooltip
                       label="已派发金额">
        <template slot-scope="scope">
          <span>{{`${scope.row.total_money_issue/100}元`}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="city"
                       show-overflow-tooltip
                       label="地市">
      </el-table-column>
      <el-table-column prop="district"
                       show-overflow-tooltip
                       label="区县">
      </el-table-column>
      <el-table-column prop="net"
                       show-overflow-tooltip
                       label="网点">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.setEmployee.showModel(scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delPacketEmployees(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetEmployee ref="setEmployee"
                 @on-success="$refs.table.fetch()"
                 :activity_id="$route.query.id"></SetEmployee>
    <el-dialog title="提示"
               :visible.sync="uploadVisible"
               width="50%">
      <div v-if="uploadResult">
        <div>成功导入{{uploadResult.succ_total}}条</div>
        <div>重复数据{{uploadResult.repeat_total}}条</div>
        <div>导入失败{{uploadResult.fail_total}}</div>
        <template v-if="uploadResult.not_marketer_arr.length>0">
          <div class="fail-phone-content">
            <div class=""
                 :key="index"
                 v-for="(item,index) in uploadResult.not_marketer_arr">
              {{item}}
            </div>
          </div>
          <div>
            <el-button @click="copyFailPhone"
                       type="text"
                       class="clipboardBtn">复制失败手机号码</el-button>
          </div>
        </template>

        <div slot="footer"
             class="dialog-footer">
          <el-button type="primary"
                     @click="uploadVisible = false">确 定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Clipboard from 'clipboard'
import TablePage from '@/components/TablePage'
import FileUpload from '@/components/FileUpload'
import FileExport from '@/components/FileExport'
import SetEmployee from './components/SetEmployee'
import { fetchPacketEmployees, delPacketEmployees } from '@/api/market'
export default {
  name: 'SendPacketEmployees',
  data () {
    return {
      uploadResult: null,
      exportUrl: '',
      exportParams: null,
      fetchPacketEmployees,
      uploadVisible: false,
      searchParams: {},
      search: [{ type: 'area' }, {
        type: 'select',
        value: '',
        key: 'search_type',
        name: '搜索类型',
        list: [
          {
            value: 'NAME',
            label: '姓名'
          },
          {
            value: 'TEL',
            label: '手机号码'
          }
        ]
      }, {
        type: 'input',
        value: '',
        key: 'search_keyword',
        placeholder: '请输入关键字'
      }]
    }
  },
  components: {
    TablePage,
    FileUpload,
    SetEmployee,
    FileExport
  },
  methods: {
    downloadExecl () {
      window.open(process.env.VUE_APP_BASE_URL + '/data/envelope/导入员工号码模板.xlsx', '_blank')
    },
    copyFailPhone () {
      let copyText = ''
      this.uploadResult.not_marketer_arr.map((item) => {
        copyText = copyText + item + '\n'
      })
      const clipboard = new Clipboard('.clipboardBtn', {
        text: () => {
          return copyText
        }
      })
      clipboard.on('success', e => {
        console.log('复制成功')
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', e => {
        // 不支持复制
        console.log('该浏览器不支持自动复制')
        // 释放内存
        clipboard.destroy()
      })
    },
    fileSuccess (res) {
      // document.execCommand('Copy')
      if (res.meta.code === 0) {
        this.uploadVisible = true
        this.uploadResult = res.data
        // console.log(res.data.not_marketer_arr)
        // let str = ''
        // res.data.not_marketer_arr.map((item) => {
        //   str += item + '</br>'
        // })
        // const text = `成功导入${res.data.succ_total}条</br>重复数据${res.data.succ_total}条，已跳过</br>导入失败${res.data.not_marketer_total}条，请先创建员工</br>${str}`
        // this.$alert(text, '导入情况', { dangerouslyUseHTMLString: true, customClass: 'class-box' })
        this.$refs.table.fetch()
      } else {
        this.$alert(res.meta.msg, { dangerouslyUseHTMLString: true, customClass: 'class-box' })
      }
    },
    exportNetsData () {
      window.open(this.exportUrl, '_blank')
    },
    setNet (id) {
      this.$router.push({
        name: 'SetNet',
        query: {
          id: id
        }
      })
    },
    delPacketEmployees (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delPacketEmployees({
          activity_id: this.$route.query.id,
          marketer_config_ids: [e.id]
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      })
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    }

  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
.class-box {
  max-height: 500px;
  overflow-y: scroll;
}
.fail-phone-content {
  margin: 15px 0px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  padding: 15px;
  max-height: 200px;
  overflow-y: scroll;
}
</style>
