import request from '@/utils/request'

export function fetchEmployees (query) {
  return request({
    url: '/api/mall/admin/marketer/get/list',
    method: 'get',
    params: query
  })
}
// 编辑员工
export function setEmployee (data) {
  return request({
    url: '/api/mall/admin/marketer/edit',
    method: 'post',
    data
  })
}
// 删除员工
export function delStore (data) {
  return request({
    url: '/api/mall/admin/marketer/delete',
    method: 'post',
    data
  })
}
// 获取管理用户列表
export function fetchManagers (query) {
  return request({
    url: '/api/mall/admin/user/get/list',
    method: 'get',
    params: query
  })
}
// 添加/编辑管理员
export function setManager (data) {
  return request({
    url: '/api/mall/admin/user/edit',
    method: 'post',
    data
  })
}
// 删除管理用户接口
export function delManager (data) {
  return request({
    url: '/api/mall/admin/user/delete',
    method: 'post',
    data
  })
}
// 获取banner列表
export function fetchBanners (query) {
  return request({
    url: '/api/mall/admin/banner/get/list',
    method: 'get',
    params: query
  })
}
// 编辑banner接口
export function setBanner (data) {
  return request({
    url: '/api/mall/admin/banner/edit',
    method: 'post',
    data
  })
}
// 删除banner
export function delBanner (data) {
  return request({
    url: '/api/mall/admin/banner/delete',
    method: 'post',
    data
  })
}
// 获取网点列表接口
export function fetchNets (query) {
  return request({
    url: '/api/mall/admin/network/get/list',
    method: 'get',
    params: query
  })
}
// 删除网点接口
export function delNet (data) {
  return request({
    url: '/api/mall/admin/network/delete',
    method: 'post',
    data
  })
}
// 网点详情
export const getNetDeail = async (params) => {
  return request({
    url: '/api/mall/admin/network/detail',
    method: 'get',
    params
  })
}
// 网点编辑接口
export function setNet (data) {
  return request({
    url: '/api/mall/admin/network/edit',
    method: 'post',
    data
  })
}
